const moment = require('moment-timezone')

const Constants = require('./constants.js')

export const COST_PER_DAY = {
  'Arts': {
    0: 45,
    1: 45,
    2: 45,
    3: 45,
    4: 45,
    5: 45,
    6: 45
  },

  'Attractions': {
    0: 25,
    1: 25,
    2: 25,
    3: 25,
    4: 25,
    5: 25,
    6: 25
  },

  'Premium': {
    0: 65,
    1: 65,
    2: 65,
    3: 65,
    4: 65,
    5: 65,
    6: 65
  },
}

export const PER_TICKET_FEE = 9
export const PER_TICKET_NEXT_DAY_FEE = 12
export const PER_TICKET_HOLIDAY_FEE = 15
export const PER_TICKET_HOLIDAY_NEXT_DAY_FEE = 18
export const PER_TICKET_EXTRA_ELIMINATIONS_FEE = 5

export const PER_TICKET_TICKET_FLEX_FEE = 5
export const PER_ORDER_RESTAURANT_FEE = 5
export const PER_ORDER_PARKING_FEE = 3
export const NEXT_DAY_RESERVATION_PRICE = 7
export const REGULAR_RESERVATION_PRICE = 5

export const HOLIDAY_DATES = ['2021-11-23', '2021-11-24', '2021-11-25', '2021-11-26', '2021-11-27', '2021-11-28',
                              '2021-12-21', '2021-12-22', '2021-12-23', '2021-12-24', '2021-12-25',
                              '2021-12-26', '2021-12-27', '2021-12-28', '2021-12-29', '2021-12-30']

export const getPricingForOrder = (order, user, serverPricing) => {
  var credits = user && user.purchaseCredits

  var numberOfTickets = order.numberOfTickets
  var requestedDate = moment(order.requestedDate, 'MM-DD-YYYY')
  var eventPreference = order.eventPreference === 'none' ? 'Arts' : order.eventPreference

  var ticketCostOnDate = COST_PER_DAY[eventPreference][requestedDate.day()]
  var perTicketFee = PER_TICKET_FEE

  var ticketFlexFeePerTicket = order.isRequestingTicketFlex ? PER_TICKET_TICKET_FLEX_FEE : 0
  var restaurantFee = order.isRequestingRestaurantReservation ? PER_ORDER_RESTAURANT_FEE : 0
  var parkingFee = order.isRequestingParkingReservation ? PER_ORDER_PARKING_FEE : 0
  var extraEliminationsFeePerTicket = order.isRequestingAdditionalExclusions ? PER_TICKET_EXTRA_ELIMINATIONS_FEE : 0

  var tomorrowEST = moment().tz(Constants.LOCALE).add(1, 'days').format('YYYY-MM-DD')
  var isRequestedDateTomorrow = (requestedDate.format('YYYY-MM-DD') === tomorrowEST)
  var isRequestedDateHoliday = HOLIDAY_DATES.includes(requestedDate.format('YYYY-MM-DD'))

  if (isRequestedDateTomorrow && isRequestedDateHoliday) {
    perTicketFee = PER_TICKET_HOLIDAY_NEXT_DAY_FEE
  } else if (isRequestedDateHoliday) {
    perTicketFee = PER_TICKET_HOLIDAY_FEE
  } else if (isRequestedDateTomorrow) {
    perTicketFee = PER_TICKET_NEXT_DAY_FEE
  }

  var addOnFeesPerTicket = ticketFlexFeePerTicket + extraEliminationsFeePerTicket
  var addOnFeesPerOrder = restaurantFee + parkingFee
  var costPerTicket = ticketCostOnDate + perTicketFee + addOnFeesPerTicket

  var totalCostPreCredit = (numberOfTickets * costPerTicket) + addOnFeesPerOrder

  var creditAmount = credits && credits.length > 0 ? credits[0].remainingAmount : 0
  var totalCost = totalCostPreCredit - creditAmount <= 0 ? 0 : (totalCostPreCredit - creditAmount)
  var usedCredit = totalCostPreCredit - totalCost

  var discountAmount = 0
  var giftCardAmount = 0

  if (serverPricing) {
    totalCost = serverPricing.price
    discountAmount = serverPricing.discountAmount
    giftCardAmount = serverPricing.giftCardAmount
  }

  const toFixedPrecisionNumber = (num, decimalPlaces) => {
    var precisionFactor = Math.pow(10, decimalPlaces)

    return Math.round(num * precisionFactor) / precisionFactor
  }

  return {
    allExtraEliminationsFeePerTicket: toFixedPrecisionNumber(extraEliminationsFeePerTicket, 2),
    costPerTicket: toFixedPrecisionNumber(costPerTicket, 2),
    date: moment(requestedDate).format('MM-DD-YYYY'),
    discountAmount: toFixedPrecisionNumber(discountAmount, 2),
    giftCardAmount: toFixedPrecisionNumber(giftCardAmount, 2),
    parkingFee: toFixedPrecisionNumber(parkingFee, 2),
    perTicketFee: toFixedPrecisionNumber(perTicketFee, 2),
    purchaseCreditAmount: toFixedPrecisionNumber(usedCredit, 2),
    numberOfTickets: numberOfTickets,
    restaurantFee: toFixedPrecisionNumber(restaurantFee, 2),
    ticketCostOnDate: toFixedPrecisionNumber(ticketCostOnDate, 2),
    ticketFlexFeePerTicket: toFixedPrecisionNumber(ticketFlexFeePerTicket, 2),
    totalCost: toFixedPrecisionNumber(totalCost, 2),
  }
}
