import React, { useEffect, lazy, Suspense } from 'react'
import { gql } from 'apollo-boost'
import { graphql } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import { ThemeProvider, createTheme } from '@mui/material/styles'

import { GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE } from '../constants.js'

import Header from './Header'
import SpinPage from './SpinPage'

import '../styles/commissioner.css'
import './App.css'
import './SpinPage.css'

const ConfirmationPage = lazy(() => import ('./ConfirmationPage'))
const reload = () => window.location.reload()

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const theme = createTheme({
  palette: {
    primary: {
      main: '#F5C238',
      light: '#FFE38A',
      dark: '#8B6900'
    },

    secondary: {
      main: '#444444',
      light: '#4f4348',
      dark: '#000000'
    },

    white: {
      main: '#ffffff'
    },

    pink: {
      main: '#2061a1'
    },

    teal: {
      main: '#1D8091'
    }
  },
})

function App(props) {

  var user = props.data.user

  return (
    <div>
      <Helmet>
        <title>Goldstar Roulette</title>
        <meta name="description" content="You pick the day, we pick the event" />
      </Helmet>

      <ThemeProvider theme={theme}>
        <div>
          <Header user={user} />

          <div style={{minHeight: '50vh'}}>
            <Suspense fallback={<div></div>}>
              <div>
                <Switch onChange={window.scrollTo(0, 0)}>

                  {/* Dynamic Pages  */}
                  <Route exact path='/confirmation/:orderId' render={(props) => <ConfirmationPage {...props}/> }/>
                  <Route exact path='/' render={(props) => <SpinPage {...props} isSafari={isSafari} theme={theme} user={user}/> }/>

                  {/* Constants */}
                  <Route exact path='/sitemap.xml' onEnter={reload}/>
                  <Route path='*' render={(props) => <SpinPage {...props} isSafari={isSafari} theme={theme} user={user}/> }/>

                </Switch>
              </div>
            </Suspense>
          </div>

        </div>
      </ThemeProvider>
    </div>
  )
}

const USER_QUERY = gql`
  query {
    user {
      id
      email
      firstName
      lastName
    }
  }
`

export default graphql(USER_QUERY, {
  options: { fetchPolicy: 'network-only' }
})(withRouter(App))
