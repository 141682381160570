import React, { useCallback, useState, useEffect, lazy } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'

import BRClient from '../broadwayRouletteClient'
import {
  ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER,
  ARTS_ELIMINATIONS,
  ARTS_TIME,
  ATTRACTIONS_ELIMINATIONS,
  ATTRACTIONS_TIME,
  EVENT_CATEGORY_DETAILS,
  LOCALE,
  RATE_LIMIT_PERIOD_DEFAULT,
  EVENT_RATE_LIMIT_DEFAULT,
  PREMIUM_ELIMINATIONS,
  PREMIUM_TIME,
  STRIPE_ACCESS_KEY,
  TODAY,
  USD_DOLLARS_TO_CENTS,
} from '../constants'

import { GA_ACTION, GA_EVENT, sendGAEvent, sendPurchaseGAEvent } from '../analytics'

import { getDateStr, getDateStrYYYYMMDD, getInternationalPhoneNumber } from '../utils'
import { getDisabledDates } from '../frequencyCap'
import { isValidOrder } from '../orderValidation'

import {
  getPricingForOrder,
  HOLIDAY_DATES,
  PER_TICKET_EXTRA_ELIMINATIONS_FEE,
  PER_TICKET_TICKET_FLEX_FEE,
  PER_ORDER_RESTAURANT_FEE,
  PER_ORDER_PARKING_FEE
} from '../orderPricing'

import rouletteWheel from '../images/goldstar-roulette-wheel.webp'
import box from '../images/box.webp'

import desktopHeader from '../images/desktop-header.webp'
import tabletHeader from '../images/tablet-header.webp'
import mobileHeader from '../images/mobile-header.webp'


const AddOn = lazy(() => import ('./AddOn.js'))
const DateAndTicketSelection = lazy(() => import ('./DateAndTicketSelection.js'))
const EventElimination = lazy(() => import ('./EventElimination.js'))
const PaymentForm = lazy(() => import ('./CheckoutForms.js'))

const MIN_DATE = moment(TODAY) > moment('2022-08-01') ? moment(TODAY).add(3, 'days') : moment('2022-08-01')
const STRIPE_PROMISE = loadStripe(STRIPE_ACCESS_KEY)

// Return tomorrow's date as a moment object
const getFutureAsDate = (daysAhead = 1) => {
  var dateTomorrow = moment().tz(LOCALE).add(daysAhead, 'days').toDate()
  var futureDate = TODAY >= moment('2022-08-01') ? dateTomorrow : MIN_DATE.toDate()

  return futureDate
}

function SpinPage(props) {
  // Create states
  const [alertStr, setAlertStr] = useState('')
  const [verificationStr, setVerificationStr] = useState('')

  const [isAlertPopupVisible, setIsAlertPopupVisible] = useState(false)
  const [isHolidayPopupVisible, setIsHolidayPopupVisible] = useState(false)
  const [isNoEliminationsPopupVisible, setIsNoEliminationsPopupVisible] = useState(false)
  const [isTicketFlexPopupVisible, setIsTicketFlexPopupVisible] = useState(false)
  const [isCategoryDisabledPopupVisible, setIsCategoryDisabledPopupVisible] = useState(false)

  const [isUpdatingPriceWithCode, setIsUpdatingPriceWithCode] = useState(false)
  const [isResolvingPurchase, setIsResolvingPurchase] = useState(false)
  const [isGiftCardQuerying, setIsGiftCardQuerying] = useState(false)
  const [isPromoQuerying, setIsPromoQuerying] = useState(false)

  const [hasLoadedUser, setHasLoadedUser] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const isMobile = windowWidth < 800

  const [user, setUser] = useState({
    id: '',
    email: '',
    eventOrders: {},
    firstName: '',
    lastName: '',
    isNewUser: true,
    lastName: '',
    mobilePhone: '',
    purchaseCredits: [],
  })

  var defaultRequestedDate = getFutureAsDate()
  var defaultEventPreference = isMobile ? 'none' : 'Arts'

  const [order, setOrder] = useState({
    location: 'Los Angeles',
    retailer: 'Goldstar',
    numberOfTickets: 2,
    requestedDate: defaultRequestedDate,
    requestedTime: 'Evening',
    rouletteType: 'Regular',
    isBundle: false,
    isRequestingAdditionalExclusions: false,
    isRequestingParkingReservation: false,
    isRequestingRestaurantReservation: false,
    isRequestingTicketFlex: false,
    eventPreference: defaultEventPreference,
    isOptingIntoMailingList: false,
    clientVersion: '1.0.0'
  })

  const [orderVerified, setOrderVerified] = useState(false)
  const [userEliminatedEvents, setUserEliminatedEvents] = useState({})

  const [frequencyCap, setFrequencyCap] = useState('')

  const [giftCardCode, setGiftCardCode] = useState('')
  const [purchaseCode, setPurchaseCode] = useState('')

  const [userAcceptedNoEliminationsWarning, setUserAcceptedNoEliminationsWarning] = useState(false)
  const [userAcceptedMailingList, setUserAcceptedMailingList] = useState(false)

  const [emailDirty, setEmailDirty] = useState(true)
  const [phoneDirty, setPhoneDirty] = useState(true)
  const [pricingDirty, setPricingDirty] = useState(true)
  const [serverPricing, setServerPricing] = useState(false)

  const [userExistsForEmail, setUserExistsForEmail] = useState(false)
  const [userExistsForPhone, setUserExistsForPhone] = useState(false)

  const [adminDisabledDates, setAdminDisabledDates] = useState([])

  const getIsUserAuthenticated = () => {
    return user && user.id
  }

  const isUserAuthenticated = getIsUserAuthenticated()

  // Set first available event date to tomorrow
  const autoAdvanceNextAvailableEventDate = () => {
    var nextAvailableEventDate = getFutureAsDate()
    var eventTimeForNextAvailableEventDate = getDefaultEventTime(nextAvailableEventDate)

    var newOrderState = {...order}

    newOrderState.requestedDate = nextAvailableEventDate
    newOrderState.requestedTime = eventTimeForNextAvailableEventDate

    setOrder(newOrderState)
  }

  const ensureValidDateTimeSelected = () => {
    var requestedDate = moment(order.requestedDate, 'MM-DD-YYYY')

    // Format disabled dates list
    var disabledDates = getAllDisabledDates()
    var disabledDatesSet = {}
    disabledDates.forEach(date => {
      disabledDatesSet[moment(date).format('YYYY-MM-DD')] = true
    })

    // Determine if input is a valid date
    var isInvalidDate = (date) => (
      moment(date).format('YYYY-MM-DD') in disabledDatesSet ||
      moment(date).format('YYYY-MM-DD') < MIN_DATE
    )

    if (!isInvalidDate(requestedDate)) {
      return
    } else {
      // Loop through requested dates until we find a valid date
      while (isInvalidDate(requestedDate)) {
        requestedDate = moment(requestedDate).add(1, 'days').toDate()
      }

      // Get default event time for found valid date
      var requestedTime = getDefaultEventTime(requestedDate)
      var newOrderState = {...order}

      newOrderState.requestedDate = requestedDate
      newOrderState.requestedTime = requestedTime

      setOrder(newOrderState)
    }
  }

  const getCharge = (user, order) => {
    var userEmail = user.email
    var orderDetails = getOrder()
    var pricingInfo = getPricingForOrder(orderDetails, user, serverPricing)

    return {
      description: userEmail,
      zipCode: true,
      amount: pricingInfo.totalCost * USD_DOLLARS_TO_CENTS
    }
  }

  // Return default time based off day of week and event preference
  const getDefaultEventTime = (requestedDateMoment) => {
    var requestedDateString = moment(requestedDateMoment).format('YYYY-MM-DD')
    var disabledOptionsForDate = getDisabledOptionsForDate(requestedDateString)
    var requestedTime = 'Evening'

    var selectedDateEveningDisabledDates = disabledOptionsForDate.evening.filter(disabledDate => (
      disabledDate.rouletteType === 'All'
    ))

    var selectedDateAfternoonDisabledDates = disabledOptionsForDate.afternoon.filter(disabledDate => (
      disabledDate.rouletteType === 'All'
    ))

    var selectedDateAllDayDisabledDates = disabledOptionsForDate.allday.filter(disabledDate => (
      disabledDate.rouletteType === 'All'
    ))

    var isEveningEnabled = selectedDateEveningDisabledDates.length === 0
    var isAfternoonEnabled = selectedDateAfternoonDisabledDates.length === 0
    var isAllDayEnabled = selectedDateAllDayDisabledDates.length === 0

    if (isEveningEnabled && order.eventPreference === 'Arts') {
      requestedTime = 'Evening'
    } else if (isAfternoonEnabled && order.eventPreference === 'Attractions') {
      requestedTime = 'Afternoon'
    } else if (isAllDayEnabled) {
      requestedTime = 'AllDay'
    } else {
      // TODO: what if all times are cancelled and the disabled dates filter missed the date?
    }

    return requestedTime
  }

  const getAllDisabledDates = () => {
    // Get disabled dates set by admins
    var fullyDisabledDates = adminDisabledDates.filter(disabledDate => disabledDate.rouletteType === 'All' &&
                                                                       disabledDate.rouletteTime === 'All')
                                               .map(disabledDate => { return disabledDate.date })
    var disabledDates = [...fullyDisabledDates]

    // Get event rate limit and limit period
    var eventRateLimit = (frequencyCap && frequencyCap.eventRateLimit) || EVENT_RATE_LIMIT_DEFAULT
    var rateLimitPeriod = (frequencyCap && frequencyCap.rateLimitPeriod) || RATE_LIMIT_PERIOD_DEFAULT

    // See if user's previous order history exceeds limit, and if so, disable dates for user to enforce rate limit
    var orderDates = user.eventOrders.length > 0 && user.eventOrders
                     .filter(order => order.isValidOrder && order.location.name === 'Los Angeles' && order.retailer.name === 'Goldstar')
                     .map(order => getDateStr(order.requestedDate))
    var frequencyCappedDisabledDates = getDisabledDates(orderDates, eventRateLimit, rateLimitPeriod)

    return [...disabledDates, ...frequencyCappedDisabledDates]
  }

  // Retrieve disabled options set by admins
  const getDisabledOptionsForDate = (requestedDateString) => {
    // Get all valid disabled dates for selected show date
    var selectedDateDisabledDates = adminDisabledDates.filter(disabledDate => (
      (disabledDate.date === requestedDateString && disabledDate.isActive)
    ))

    // Get all valid disabled dates for selected show date by time
    var eveningDisabledDates = selectedDateDisabledDates.filter(disabledDate => (
      (disabledDate.rouletteTime === 'Evening' || disabledDate.rouletteTime === 'All')
    ))

    var afternoonDisabledDates = selectedDateDisabledDates.filter(disabledDate => (
      (disabledDate.rouletteTime === 'Afternoon' || disabledDate.rouletteTime === 'All')
    ))

    var allDayDisabledDates = selectedDateDisabledDates.filter(disabledDate => (
      (disabledDate.rouletteTime === 'All-Day' || disabledDate.rouletteTime === 'All')
    ))

    return ({ evening: eveningDisabledDates, afternoon: afternoonDisabledDates, allday: allDayDisabledDates })
  }

  // Filter and format disabled dates
  const getFilteredDisabledDates = (adminDisabledDatesQuery, category, key) => {
    var adminDisabledFilteredDates = adminDisabledDatesQuery.data.disabledEventDatesList.items
                                     .filter(disabledDate => disabledDate[category] === key)
                                     .map(disabledDate => {
                                       return moment(disabledDate.date).tz(LOCALE).toDate()
                                     })

    return adminDisabledFilteredDates
  }

  // Format and return all order data
  const getOrder = (serverFriendly = false) => {
    var requestedDate = getDateStr(order.requestedDate)
    if (serverFriendly) {
      // Client needs date formatted MM-DD-YYYY, server needs date formatted YYYY-MM-DD
      requestedDate = getDateStrYYYYMMDD(order.requestedDate)
    }

    return {
      email: user.email,
      excludedEvents: Object.keys(userEliminatedEvents),
      location: order.location,
      retailer: order.retailer,
      numberOfTickets: order.numberOfTickets,
      requestedDate: requestedDate,
      requestedTime: order.requestedTime,
      rouletteType: order.rouletteType,
      isBundle: order.isBundle,
      isNewUser: user.isNewUser,
      isRequestingAdditionalExclusions: !!order.isRequestingAdditionalExclusions,
      isRequestingParkingReservation: !!order.isRequestingParkingReservation,
      isRequestingRestaurantReservation: !!order.isRequestingRestaurantReservation,
      isRequestingTicketFlex: order.isRequestingTicketFlex,
      eventPreference: order.eventPreference,
      isOptingIntoMailingList: false,
      clientVersion: '1.0.0'
    }
  }

  // Format price for copy
  const getPriceString = (price) => {
    var isDollarAmount = (0 < price.toFixed(2).indexOf('.00'))

    if (isDollarAmount) {
      return price.toFixed(0)
    } else {
      return price.toFixed(2)
    }
  }

  // Retrieve and format a users previously attended events
  const getPreviouslyAttendedEvents = () => {
    if (!isUserAuthenticated || !user.eventOrders || !(user.eventOrders instanceof Array)) { return [] }

    var previouslySeenEvents = user.eventOrders.filter(order => {
      return order.isValidOrder && order.requestedDate < moment().format('YYYY-MM-DD') &&
             order.location.name === 'Los Angeles' && order.retailer.name === 'Goldstar'
    })
    .map(order => order.ticketDetails.event)
    .filter(event => event && event.id)

    var previouslySeenEventIds = {}
    previouslySeenEvents.forEach(event => { previouslySeenEventIds[event.id] = true })

    return previouslySeenEventIds
  }

  const handleChangeGiftCardCode = (newGiftCardCode) => {
    setGiftCardCode(newGiftCardCode.toUpperCase())
    lazyApplyGiftCardCode()
  }

  const handleChangePurchaseCode = (newPurchaseCode) => {
    setPurchaseCode(newPurchaseCode.toUpperCase())
    lazyApplyPurchaseCode()
  }

  const handleUpdateOrder = (key, value) => {
    var newOrderState = {...order}

    newOrderState[key] = value
    setOrder(newOrderState)
  }

  const handleUpdateUser = (key, value) => {
    var newUserState = {...user}
    var formattedValue = key === 'mobilePhone' ? getInternationalPhoneNumber(value) || value : value

    newUserState[key] = formattedValue
    setUser(newUserState)

    if (key === 'email') {
      lazySearchUserEmail()
      resetPurchaseCode()
    }
  }

  const handleChangeEventPreference = (togglePreference) => {
    handleUpdateOrder('eventPreference', togglePreference)

    setUserEliminatedEvents([])
    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.EVENT_PREFERENCE)
  }

  const handleCreateOrder = async (paymentResult, paymentSource, paymentMethod) => {
    return new Promise(async (resolve, reject) => {
      setIsResolvingPurchase(true)

      var userOrderCount = user.eventOrders && user.eventOrders.length > 0
                           ? user.eventOrders.filter(order => order.isValidOrder).length
                           : 0
      user.eventOrders = userOrderCount
      var charge = getCharge(user, order)

      // Create an order object consumable by the BE
      var formattedOrder = getOrder(true)

      if (paymentMethod && paymentMethod.billing_details) {
        user.zipCode = paymentMethod.billing_details.address.postal_code
      }

      try {
        var response = await BRClient.createEventOrder(charge, user, formattedOrder, purchaseCode, giftCardCode,
                                                       paymentSource, paymentResult)
        var responseJson = await response.json()

        resolve(responseJson)

      } catch (e) {
        console.log(e)
        handlePaymentFailure({
          message: `We're sorry! We weren't able to place your order just now. Please try again in a little while.`
        })
      }
    })
  }

  const handleClickPurchase = () => {
    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.SUBMIT_PURCHASE)

    var errorMessage = alertStr || verificationStr

    var hasNoEliminations = Object.keys(userEliminatedEvents).length === 0
    var hasWarning = hasNoEliminations && !userAcceptedNoEliminationsWarning

    if (hasWarning) {
      setIsNoEliminationsPopupVisible(true)
    } else if (errorMessage) {
      sendGAEvent(GA_EVENT.EVENT, GA_ACTION.EVENT.ORDER_INVALID)
      console.log(errorMessage)
      setIsAlertPopupVisible(true)
    }
  }

  const handleConfirmPayment = async (paymentResult, orderId) => {
    return new Promise(async (resolve, reject) => {
      try {
        var charge = getCharge(user, order)

        var response = await BRClient.confirmStripePayment(paymentResult, charge, 'usd', orderId)
        var responseJson = await response.json()

        resolve(responseJson)

      } catch (e) {
        console.log(e)
        handlePaymentFailure({
          message: `We're sorry! We weren't able to place your order just now. Please try again in a little while.`
        })
      }
    })
  }

  const handlePaymentFailure = ({ message }) => {
    setAlertStr(message)
    setIsResolvingPurchase(false)
    setIsAlertPopupVisible(true)

    console.log(message)

    sendGAEvent(GA_EVENT.EVENT, GA_ACTION.EVENT.PAYMENT_FAILED)
  }

  const handlePaymentSuccess = (orderId) => {
    var charge = getCharge(user, order)
    var orderDetails = getOrder()
    var pricing = getPricingForOrder(orderDetails, user, serverPricing)

    var numberOfTickets = order.numberOfTickets
    var requestedDate = order.requestedDate
    var totalCost = charge.amount / USD_DOLLARS_TO_CENTS

    sendPurchaseGAEvent(orderId, totalCost, purchaseCode)
    props.history.push(`/confirmation/${orderId}`)
  }

  const handleVerifyOrder = () => {
    var [hasValidOrder, invalidMessage] = isOrderValid()

    var hasNoEliminations = Object.keys(userEliminatedEvents).length === 0
    var hasWarning = hasNoEliminations && !userAcceptedNoEliminationsWarning

    var needsInvalidOrderStateSet = !hasValidOrder && !alertStr &&
                                    (verificationStr !== invalidMessage || hasWarning || orderVerified)

    var needsValidOrderStateSet = hasValidOrder && !hasWarning && !alertStr

    var verificationStr = needsInvalidOrderStateSet ? invalidMessage : ''
    var isOrderVerified = needsValidOrderStateSet

    setVerificationStr(verificationStr)
    setOrderVerified(isOrderVerified)
  }

  const handleAcceptTicketflex = () => {
    setIsTicketFlexPopupVisible(false)
    setPricingDirty(true)
    handleUpdateOrder('isRequestingTicketFlex', true)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.TICKETFLEX_YES_POPUP)
  }

  const handleRejectTicketflex = () => {
    setIsTicketFlexPopupVisible(false)
    setPricingDirty(true)
    handleUpdateOrder('isRequestingTicketFlex', false)
  }

  const handleToggleAdditionalExclusions = () => {
    var newAdditionalExclusions = !order.isRequestingAdditionalExclusions

    setPricingDirty(true)
    handleUpdateOrder('isRequestingAdditionalExclusions', newAdditionalExclusions)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.EXTRA_EXCLUSION)
  }

  const handleToggleParkingReservation = () => {
    var newParkingReservation = !order.isRequestingParkingReservation

    setPricingDirty(true)
    handleUpdateOrder('isRequestingParkingReservation', newParkingReservation)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.PARKING_RESERVATION)
  }

  const handleToggleRestaurantReservation = () => {
    var newRestaurantReservation = !order.isRequestingRestaurantReservation

    setPricingDirty(true)
    handleUpdateOrder('isRequestingRestaurantReservation', newRestaurantReservation)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.RESTAURANT_RESERVATION)
  }

  const handleToggleTicketFlex = () => {
    var newTicketFlex = !order.isRequestingTicketFlex

    setPricingDirty(true)
    handleUpdateOrder('isRequestingTicketFlex', newTicketFlex)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.TICKETFLEX)
  }

  const handleToggleTicketFlexDialog = () => {
    setIsTicketFlexPopupVisible(false)

    sendGAEvent(GA_EVENT.VIEW, GA_ACTION.VIEW.TICKETFLEX_POPUP)
  }

  const handleRemoveAdditionalExclusions = () => {
    setPricingDirty(true)
    handleUpdateOrder('isRequestingAdditionalExclusions', false)

    sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.REMOVE_EXTRA_EXCLUSION)
  }

  const isOrderValid = () => {
    var formattedOrder = getOrder()
    var previouslySeenEvents = getPreviouslyAttendedEvents()

    var disabledDates = getAllDisabledDates()
    var disabledDatesSet = {}
    disabledDates.forEach(dateStr => { disabledDatesSet[dateStr] = true })

    var doesUserNeedToLogIn = userExistsForEmail ||userExistsForPhone
    var [hasValidOrder, invalidMessage] = isValidOrder(formattedOrder, user, doesUserNeedToLogIn,
                                                       disabledDatesSet, isUserAuthenticated, previouslySeenEvents)

    return [hasValidOrder, invalidMessage]
  }

  const lazySearchUserEmail = useCallback(_.debounce(() => { setEmailDirty(true) }, 500), [])

  const lazySearchUserPhone = useCallback(_.debounce(() => { setPhoneDirty(true) }, 500), [])

  const lazyApplyGiftCardCode = useCallback(_.debounce(() => {
    setIsGiftCardQuerying(true)
    setPricingDirty(true)
  }, 500), [])

  const lazyApplyPurchaseCode = useCallback(_.debounce(() => {
    setIsPromoQuerying(true)
    setPricingDirty(true)
  }, 500), [])

  const loadAdminDisabledDates = async () => {
    try {
      if (!props.adminDisabledDatesQuery.loading && !props.adminDisabledDatesQuery.error) {
        var adminDisabledDatesQuery = props.adminDisabledDatesQuery
        setAdminDisabledDates(adminDisabledDatesQuery.disabledEventDatesList.items)
      }

    } catch (err) {
      console.log(`Unable to load excluded dates: ${err.message}`)
      console.log(err)
    }
  }

  const loadConfig = async () => {
    try {
      var configQuery = await props.client.query({
        query: CONFIG_JSON_QUERY,
        variables: { key: 'frequencyCap' }
      })

      setFrequencyCap(configQuery.data.configJson.value)

    } catch (err) {
      console.log(`Unable to load config: ${err.message}`)
    }
  }

  const loadUser = async () => {
    try {
      var userQuery = await BRClient.getUserDetails(email)
      var userDetails = await userQuery.json()

      if (!userDetails) {
        setHasLoadedUser(true)
        return
      }

      var email = userDetails.email
      var firstName = userDetails.firstName
      var isNewUser = false
      var lastName = userDetails.lastName
      var mobilePhone = userDetails.mobilePhone

      var eventOrders = userDetails.eventOrders.items && userDetails.eventOrders.items.map(order => {
                          if (order.isValidOrder && order.location.name === 'Los Angeles' && order.retailer.name === 'Goldstar') {
                            return order
                          }
                        })

      var capDate = moment().subtract(3, 'year').format("YYYY-MM-DD")
      var previousSpinsCount = eventOrders ? eventOrders.filter(order => ( order.orderPlacedAt > capDate )).length : 0

      var previouslySeenEvents = eventOrders ? eventOrders.filter(order => (
                                   order.orderPlacedAt < TODAY && order.ticketDetails && order.ticketDetails.event
                                 )) : []

      var purchaseCredits = userDetails.purchaseCredits ? userDetails.purchaseCredits.items.filter(credit => (
                              !credit.isExpired && credit.currency === 'USD' && credit.remainingAmount > 0
                            )) : []

      var userEliminatedEvents = {}

      setUser({
        id: userDetails.id,
        email,
        firstName,
        isNewUser,
        lastName,
        mobilePhone,
        eventOrders,
        previousSpinsCount,
        purchaseCredits,
        userEliminatedEvents
      })

      setHasLoadedUser(true)
    } catch (err) {
      console.log(`Unable to get data for user: ${err.message}`)
      setHasLoadedUser(true)
    }
  }

  const resetPurchaseCode = () => {
    setPurchaseCode('')
    setPricingDirty(true)
  }

  const updatePricingWithCode = async () => {
    setIsUpdatingPriceWithCode(true)
    setServerPricing(null)

    var isValidGiftCard = false
    var isValidPromoCode = false
    var purchaseCodeQuery = null
    var purchaseCodeData = null

    if (giftCardCode) {
      try {
        purchaseCodeQuery = await props.client.query({
          query: PURCHASE_CODE_QUERY,
          variables: {
            code: giftCardCode.toUpperCase()
          }
        })

        purchaseCodeData = purchaseCodeQuery.data.purchaseCode
        if (purchaseCodeData && purchaseCodeData.purchaseCodeType === 'GiftCard') {
          isValidGiftCard = true
        }
      } catch (e) {
        console.log(`Unable to get gift card pricing. Error: ${e.message}`)
      }
    }

    if (purchaseCode) {
      try {
        purchaseCodeQuery = await props.client.query({
          query: PURCHASE_CODE_QUERY,
          variables: {
            code: purchaseCode.toUpperCase()
          }
        })

        purchaseCodeData = purchaseCodeQuery.data.purchaseCode
        if (purchaseCodeData && purchaseCodeData.purchaseCodeType !== 'GiftCard') {
          isValidPromoCode = true
        }
      } catch (err) {
        console.log(`Unable to load purchase code data: ${err.message}`)
      }
    }

    if (!isValidGiftCard && !isValidPromoCode) {
      setIsGiftCardQuerying(false)
      setIsPromoQuerying(false)
      setIsUpdatingPriceWithCode(false)

      return
    }

    try {
      var validatedGiftCard = isValidGiftCard ? giftCardCode : null
      var validatedPromoCode = isValidPromoCode ? purchaseCode : null
      var formattedOrder = getOrder(true)

      var userOrderCount = user.eventOrders && user.eventOrders.length > 0
                           ? user.eventOrders.filter(order => order.isValidOrder).length
                           : 0
      user.eventOrders = userOrderCount

      var response = await BRClient.getPriceForEventOrder(formattedOrder, user, validatedPromoCode,
                                                          validatedGiftCard, "Los Angeles")
      var bodyJsonObj = await response.json()

      if (response.status === 200) {
        setServerPricing(bodyJsonObj)
      }
    } catch (e) {
      console.log(`Unable to get updated pricing. Error: ${e.message}`)
    }

    setIsGiftCardQuerying(false)
    setIsPromoQuerying(false)
    setIsUpdatingPriceWithCode(false)
  }

  useEffect(() => {
    loadConfig()
    autoAdvanceNextAvailableEventDate()
  }, [])

  useEffect(() => {
    loadAdminDisabledDates()
  }, [props.adminDisabledDatesQuery])

  useEffect(async () => {
    try {
      var response = await BRClient.getUserDetailsFromGoldstar()
      var responseJson = await response.json()
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    ensureValidDateTimeSelected()
  }, [disabledDates, order, user])

  useEffect(() => {
    // if event preference is changed, clear restaurant reservation add-on and update pricing
    handleUpdateOrder('isRequestingRestaurantReservation', false)
    setPricingDirty(true)
  }, [order.eventPreference])

  useEffect(() => {
    handleVerifyOrder()
  }, [order, user, userAcceptedNoEliminationsWarning, userEliminatedEvents])

  useEffect(() => {
    if (pricingDirty) {
      setPricingDirty(false)
      updatePricingWithCode()
    }
  }, [giftCardCode, purchaseCode, pricingDirty])

  useEffect(() => {
    if (!hasLoadedUser) {
      loadUser()
    }
  }, [hasLoadedUser, user])

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  var requestedDateString = moment(order.requestedDate).format('YYYY-MM-DD')
  var dayOfWeekRequested = moment(order.requestedDate, 'MM-DD-YYYY').format('dddd')

  var disabledDates = getAllDisabledDates()

  // Load disabled dates for selected date
  var disabledOptionsForDate = getDisabledOptionsForDate(requestedDateString)
  // Narrow down disabled dates to selected time
  var disabledOptionsForTime = disabledOptionsForDate[order.requestedTime.toLowerCase()]

  // Check if any times for selected date are entirely disabled for all spin types
  var selectedDateEveningDisabledDates = disabledOptionsForDate.evening.filter(disabledDate => (
    disabledDate.rouletteType === 'All'
  ))

  var selectedDateAfternoonDisabledDates = disabledOptionsForDate.afternoon.filter(disabledDate => (
    disabledDate.rouletteType === 'All'
  ))

  var selectedDateAllDayDisabledDates = disabledOptionsForDate.allday.filter(disabledDate => (
    disabledDate.rouletteType === 'All'
  ))

  var orderDetails = getOrder()
  var pricing = getPricingForOrder(orderDetails, user, serverPricing)
  var discountAmount = (serverPricing && serverPricing.discountAmount) || 0
  var giftCardAmount = (serverPricing && serverPricing.giftCardAmount) || 0
  var totalCost = pricing.totalCost
  var costPerTicket = pricing.ticketCostOnDate

  discountAmount = getPriceString(discountAmount)
  giftCardAmount = getPriceString(giftCardAmount)
  costPerTicket = getPriceString(costPerTicket)
  totalCost = getPriceString(totalCost)

  var isHolidayDate = HOLIDAY_DATES.indexOf(requestedDateString) > -1 ? true : false
  var eventPreference = order.eventPreference === 'none' ? 'Arts' : order.eventPreference

  return (
    <div className="spin static-page">
      <Helmet>
        <title>Goldstar Roulette</title>
        <meta name="description" content="Play Goldstar Roulette to attend an LA event on a budget" />
      </Helmet>

      <Stack className="page-width-stack">
        <div className="header-section">
          {windowWidth > 950 &&
            <img src={desktopHeader} className="header-image" width="1800px" />
          }

          {windowWidth <= 950 && windowWidth > 420 &&
            <img src={tabletHeader} className="header-image" width="1000px" />
          }

          {windowWidth <= 420 &&
            <img src={mobileHeader} className="header-image" width="500px" />
          }
        </div>

        <hr className="divider" />

        <DateAndTicketSelection disabledDates={disabledDates}
                                getDefaultEventTime={getDefaultEventTime}
                                handleUpdateOrder={handleUpdateOrder}
                                minDate={MIN_DATE}
                                order={order}
                                resetPurchaseCode={resetPurchaseCode}
                                setOrder={setOrder}
                                setPricingDirty={setPricingDirty}
                                setPurchaseCode={setPurchaseCode}
                                user={user}
                                windowWidth={windowWidth} />
      </Stack>

      <EventElimination disabledOptionsForDate={disabledOptionsForDate}
                        getPreviouslyAttendedEvents={getPreviouslyAttendedEvents}
                        handleUpdateOrder={handleUpdateOrder}
                        isNoEliminationsPopupVisible={isNoEliminationsPopupVisible}
                        order={order}
                        setOrder={setOrder}
                        setPricingDirty={setPricingDirty}
                        setIsNoEliminationsPopupVisible={setIsNoEliminationsPopupVisible}
                        setUserAcceptedNoEliminationsWarning={setUserAcceptedNoEliminationsWarning}
                        setUserEliminatedEvents={setUserEliminatedEvents}
                        userEliminatedEvents={userEliminatedEvents}
                        windowWidth={windowWidth} />

      <Stack className="page-width-stack">
        <div className="add-ons-section">
          <Stack className="add-ons-stack">

            <AddOn booleanCheck={order.isRequestingTicketFlex}
                   cost={`${PER_TICKET_TICKET_FLEX_FEE}/Ticket`}
                   description={'Things happen! TicketFlex gives you the flexibility to change your date up to 7 days before the event.'}
                   handleToggle={handleToggleTicketFlex}
                   title={'TicketFlex'}
                   windowWidth={windowWidth} />

            {order.eventPreference === 'Arts' &&
              <AddOn booleanCheck={order.isRequestingRestaurantReservation}
                     cost={`${PER_ORDER_RESTAURANT_FEE}`}
                     description={'Make the entire outing a surprise! We’ll make a reservation for you based on your event location and number of tickets.'}
                     handleToggle={handleToggleRestaurantReservation}
                     title={'Restaurant Reservation'}
                     windowWidth={windowWidth} />
            }
          </Stack>
        </div>
      </Stack>

      <Stack className="page-width-stack">
        <Stack className="how-step-stack">
          <Stack direction="row" alignItems="center">
            <img src={box} width="100px" height="auto" className="how-image" />
          </Stack>

          <Stack direction="column">
            <div className="how-title">Checkout to be surprised with an amazing event</div>
            <div className="how-desc">2 days before your selected date, find out which event you won!</div>
          </Stack>
        </Stack>
      </Stack>

      <div className="spin-payment">
        <Stack direction="column">
          <Stack className="bottom-stack">

            <Stack direction="column" className="inputs-section" justifyContent="space-around">
              <div className="field-spacer">
                <InputLabel className="form-label">First Name</InputLabel>
                <TextField className="input-field"
                           value={user.firstName}
                           label= {''}
                           InputLabelProps={{ shrink: true, className: "form-label" }}
                           inputProps={{ 'aria-label': 'first name' }}
                           onChange={(e) => { handleUpdateUser('firstName', e.target.value) }}
                           onClick={() => { sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.FIRST_NAME) }} />
              </div>

              <div className="field-spacer">
                <InputLabel className="form-label">Last Name</InputLabel>
                <TextField className="input-field"
                           value={user.lastName}
                           label= {''}
                           InputLabelProps={{ shrink: true, className: "form-label" }}
                           inputProps={{ 'aria-label': 'last name' }}
                           onChange={(e) => { handleUpdateUser('lastName', e.target.value) }}
                           onClick={() => { sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.LAST_NAME) }} />
              </div>

              <div className="field-spacer">
                <InputLabel className="form-label">Email</InputLabel>
                <TextField className="input-field"
                           value={user.email}
                           label= {''}
                           InputLabelProps={{ shrink: true, className: "form-label" }}
                           inputProps={{ 'aria-label': 'email' }}
                           onChange={(e) => { handleUpdateUser('email', e.target.value) }}
                           onClick={() => { sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.EMAIL) }} />
              </div>

              <div className="field-spacer">
                <InputLabel className="form-label">Mobile Phone</InputLabel>
                <TextField className="input-field"
                           value={user.mobilePhone}
                           label= {''}
                           InputLabelProps={{ shrink: true, className: "form-label" }}
                           inputProps={{ 'aria-label': 'mobile phone' }}
                           onChange={(e) => { handleUpdateUser('mobilePhone', e.target.value) }}
                           onClick={() => { sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.PHONE) }} />
              </div>
            </Stack>

            <Stack direction="column" className="checkout-section" justifyContent="space-around">
              <TextField value={giftCardCode}
                         className="input-field code"
                         variant="outlined"
                         placeholder="Gift Card Code"
                         onChange={(e) => { handleChangeGiftCardCode(e.target.value)}}/>

              <TextField value={purchaseCode}
                         className="input-field code"
                         variant="outlined"
                         placeholder="Promo Code"
                         onChange={(e) => { handleChangePurchaseCode(e.target.value)}}/>

              <div className="payment-details">
                <Stack direction="row" justifyContent="space-between" className="price-item">
                  <div className="semibold price-detail">
                    {EVENT_CATEGORY_DETAILS[eventPreference].title} Roulette
                  </div>
                  <div className="semibold price-number">{order.numberOfTickets} x ${parseFloat(costPerTicket).toFixed(2)}</div>
                </Stack>

                <hr className="payment-divider-top" />

                <Stack direction="row" justifyContent="space-between" className="price-item">
                  <div className="price-detail">Subtotal</div>
                  <div className="solo-price-number">${parseFloat(costPerTicket * order.numberOfTickets).toFixed(2)}</div>
                </Stack>

                <Stack direction="row" justifyContent="space-between" className="price-item">
                  <div className="price-detail">
                    Service Fee&nbsp;
                    {isHolidayDate &&
                      <span onClick={() => setIsHolidayPopupVisible(true) }
                            className="holiday-fee pseudo-link">(holiday fee)
                      </span>
                    }
                  </div>
                  <div className="solo-price-number">${parseFloat(pricing.perTicketFee * order.numberOfTickets).toFixed(2)}</div>
                </Stack>

                {(pricing.ticketFlexFeePerTicket !== 0) &&
                  <Stack direction="row" justifyContent="space-between" className="price-item">
                    <div className="price-detail">TicketFlex Add-On</div>
                    <div className="solo-price-number">${parseFloat(pricing.ticketFlexFeePerTicket * order.numberOfTickets).toFixed(2)}</div>
                  </Stack>
                }

                {(pricing.restaurantFee !== 0) &&
                  <Stack direction="row" justifyContent="space-between" className="price-item">
                    <div className="price-detail">Restaurant Reservation Add-On</div>
                    <div className="solo-price-number">${parseFloat(pricing.restaurantFee).toFixed(2)}</div>
                  </Stack>
                }

                {(pricing.parkingFee !== 0) &&
                  <Stack direction="row" justifyContent="space-between" className="price-item">
                    <div className="price-detail">Parking Add-On</div>
                    <div className="solo-price-number">${parseFloat(pricing.parkingFee).toFixed(2)}</div>
                  </Stack>
                }

                {(pricing.allExtraEliminationsFeePerTicket !== 0) &&
                  <Stack direction="row" justifyContent="space-between" className="price-item">
                    <div className="price-detail">
                      Extra Exclusion Add-On
                      <IconButton size="small"
                                  className="grey-button"
                                  onClick={() => handleRemoveAdditionalExclusions()}>
                        <CloseIcon fontSize="inherit" color="white" />
                      </IconButton>
                    </div>
                    <div className="solo-price-number">${parseFloat(pricing.allExtraEliminationsFeePerTicket * order.numberOfTickets).toFixed(2)}</div>
                  </Stack>
                }

                {pricing.purchaseCreditAmount !== 0 &&
                  <Stack direction="row" justifyContent="space-between" className="price-item">
                    <div className="price-detail">Credit Discount</div>
                    <div className="solo-price-number">-${parseFloat(pricing.purchaseCreditAmount).toFixed(2)}</div>
                  </Stack>
                }

                {(discountAmount !== '0') &&
                  <Stack direction="row" justifyContent="space-between" className="price-item">
                    <div className="price-detail">Promo Discount</div>
                    <div className="solo-price-number">-${parseFloat(discountAmount).toFixed(2)}</div>
                  </Stack>
                }

                {giftCardAmount !== '0' &&
                  <Stack direction="row" justifyContent="space-between" className="price-item">
                    <div className="price-detail">Gift Card Discount</div>
                    <div className="solo-price-number">-${parseFloat(giftCardAmount).toFixed(2)}</div>
                  </Stack>
                }

                {isUpdatingPriceWithCode &&
                  <CircularProgress color="secondary" />
                }

                <hr className="payment-divider-bottom" />

                <Stack direction="row" justifyContent="space-between">
                  <div className="semibold price-detail">Total</div>
                  <div className="semibold solo-price-number">${parseFloat(totalCost).toFixed(2)}</div>
                </Stack>
              </div>

              <Stack direction="row" justifyContent="space-around">
                <div className="card-inputs-custom">
                  <Elements stripe={STRIPE_PROMISE}>
                    <PaymentForm isResolvingPurchase={isResolvingPurchase}
                                 charge={getCharge(user, order)}
                                 orderVerified={orderVerified}
                                 theme={props.theme}
                                 handleClickPurchase={() => { handleClickPurchase()}}
                                 handleCreateOrder={handleCreateOrder}
                                 handleConfirmPayment={handleConfirmPayment}
                                 handlePaymentSuccess={(orderId) => { handlePaymentSuccess(orderId)}}
                                 handlePaymentFailure={(message) => { handlePaymentFailure(message)}}
                                 eventCoronaQuestions={true} />
                  </Elements>
                </div>
              </Stack>

            </Stack>
          </Stack>
        </Stack>
      </div>

      <div className="footer">
        Use of this site is governed by our <a href="https://www.goldstar.com/company/tos">Terms of Service</a>&nbsp;
        and <a href="https://www.goldstar.com/company/privacy">Privacy Policy</a> ©2022 Goldstar Events, Inc.
      </div>

      <Dialog className="popup-dialog"
              onClose={() => {
                setAlertStr: ''
                setIsAlertPopupVisible(false)
              }}
              open={(!!alertStr || !!verificationStr) && isAlertPopupVisible} >
        <div className="popup-content">
          <h2>{alertStr || verificationStr}</h2>
          <div className="popup-buttons">
            <span className="cta-button ok-button"
                  onClick={() => {
                    setAlertStr('')
                    setIsAlertPopupVisible(false)
                  }}>
              Ok!
            </span>
          </div>
          <span className="dialog-close header-description"
                onClick={() => {
                  setAlertStr('')
                  setIsAlertPopupVisible(false)
                }}>
            <CloseIcon />
          </span>
        </div>
      </Dialog>

      <Dialog className="popup-dialog"
              onClose={() => { setIsCategoryDisabledPopupVisible(false) }}
              open={isCategoryDisabledPopupVisible} >
        <div className="popup-content">
          <h2>
            Sorry, this spin category is not available for your selected date and time.
          </h2>
          <span className="dialog-close header-description"
                onClick={() => { setIsCategoryDisabledPopupVisible(false) }}>
            <CloseIcon />
          </span>
        </div>
      </Dialog>

      <Dialog className="popup-dialog"
              onClose={() => { setIsHolidayPopupVisible(false) }}
              open={isHolidayPopupVisible} >
        <div className="popup-content">
          <p>
            Due to increased demand during the holidays, select dates are subject to an increased service fee
            to maintain our low, flat rates.
          </p>
          <span className="dialog-close header-description"
                onClick={() => { setIsHolidayPopupVisible(false) }}>
            <CloseIcon />
          </span>
        </div>
      </Dialog>

    </div>
  )
}

const DISABLED_EVENT_DATES_LIST_QUERY = gql`
  query DisabledEventDatesListQuery($today: Date!) {
    disabledEventDatesList(
      sort: { date: DESC },
      filter: {
        date: { gte: $today },
        isActive: { equals: true },
        location: { name: { equals: "Los Angeles" }}
        retailer: { name: { equals: "Goldstar" }}
      }
    ){
      items {
        id
        date
        isActive
        rouletteType
        rouletteTime
      }
    }
  }
`

const CONFIG_JSON_QUERY = gql`
  query ConfigQuery($key: String!) {
    configJson(key: $key) {
      id
      key
      value
    }
  }
`

const PURCHASE_CODE_QUERY = gql`
  query PurchaseCodeQuery($code: String) {
    purchaseCode(code: $code) {
      id
      code
      isOneTimeUsePerUser
      purchaseCodeType
    }
  }
`

export default withRouter(withApollo(graphql(DISABLED_EVENT_DATES_LIST_QUERY, {
  name: 'adminDisabledDatesQuery',
  options: (ownProps) => {
    var today = moment().format('YYYY-MM-DD')

    return {
      notifyOnNetworkStatusChange: true,
      variables: {
        today
      }
    }
  }
})(SpinPage)))