import moment from 'moment-timezone'
import PhoneNumber from 'awesome-phonenumber'

import {
  ARTS_ELIMINATIONS,
  ARTS_TIME,
  ATTRACTIONS_ELIMINATIONS,
  ATTRACTIONS_TIME,
  MAX_TICKET_REQUEST,
  MIN_TICKET_REQUEST,
  PREMIUM_ELIMINATIONS,
  PREMIUM_TIME,
  TODAY,
  UPCHARGE_ADDITIONAL_EXCLUSIONS
} from './constants'

import { emailRegex } from './utils'

export const getCorrectTimeForType = (eventPreference) => {
  if (eventPreference === 'Arts') {
    return ARTS_TIME
  } else if (eventPreference === 'Attractions') {
    return ATTRACTIONS_TIME
  } else if (eventPreference === 'Premium') {
    return PREMIUM_TIME
  }
}

export const getMaxEliminationCount = (order, previouslySeenEvents) => {
  var count = 2

  if (order.eventPreference === 'Arts') {
    count = ARTS_ELIMINATIONS
  } else if (order.eventPreference === 'Attractions') {
    count = ATTRACTIONS_ELIMINATIONS
  } else if (order.eventPreference === 'Premium') {
    count = PREMIUM_ELIMINATIONS
  }

  var canRequestUpcharge = !order.isRequestingChildFriendly &&
                           order.isRequestingAdditionalExclusions

  if (canRequestUpcharge) {
    count += UPCHARGE_ADDITIONAL_EXCLUSIONS
  }

  return count
}

export const isValidOrder = (order, user, doesUserNeedToLogIn, disabledDatesSet, isUserAuthenticated, previouslySeenEvents) => {
  var maxEliminationCount = getMaxEliminationCount(order, previouslySeenEvents)

  var hasValidExclusions = Object.keys(order.excludedEvents).length <= maxEliminationCount
  var hasValidNumberOfTickets = typeof(order.numberOfTickets) === 'number' &&
                                MIN_TICKET_REQUEST <= order.numberOfTickets &&
                                order.numberOfTickets <= MAX_TICKET_REQUEST

  var hasRouletteType = !!order.rouletteType
  var hasEventPreference = !!order.eventPreference && order.eventPreference !== 'none'
  var hasEventDate = !!order.requestedDate
  var hasEventTime = !!order.requestedTime && order.requestedTime !== ""
  var isValidEventDate = !disabledDatesSet[moment(order.requestedDate, 'MM-DD-YYYY').format('YYYY-MM-DD')]

  var requestedDate = moment(order.requestedDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
  var isRequestedEventDateAfterToday = requestedDate > TODAY

  var hasFirstName = user && user.firstName
  var hasLastName = user && user.lastName

  var hasEmail = user && user.email
  var hasValidEmail = user && user.email && user.email.match(emailRegex)

  var hasMobilePhone = user && user.mobilePhone
  var pn = hasMobilePhone ? new PhoneNumber(user && user.mobilePhone) : null
  var hasProperlyFormattedMobilePhone = pn && pn.a.valid

  var invalidMessage = null
  if (doesUserNeedToLogIn) {
    invalidMessage = `This email or phone has been used before. Please log in`
  } else if (!hasValidExclusions) {
    invalidMessage = `You've excluded too many events. Please go back and remove some exclusions`
  } else if (!hasValidNumberOfTickets) {
    invalidMessage = `You've selected an invalid number of tickets.`
  } else if (!hasRouletteType) {
    invalidMessage = `You need to select a type of roulette.`
  } else if (!hasEventPreference) {
    invalidMessage = `You need to select a spin category.`
  } else if (!hasEventDate) {
    invalidMessage = `You need to specify a valid date.`
  } else if (!hasEventTime) {
    invalidMessage = `You need to specify an evening or matinee event time.`
  } else if (!isValidEventDate) {
    invalidMessage = `You need to specify a valid date.`
  } else if (!hasFirstName) {
    invalidMessage = `You need to provide a first name.`
  } else if (!hasLastName) {
    invalidMessage = `You neeed to provide a last name.`
  } else if (!hasEmail) {
    invalidMessage = `You need to provide an email address.`
  } else if (!hasValidEmail) {
    invalidMessage = `You need to provide a valid email address.`
  } else if (!hasMobilePhone) {
    invalidMessage = `You need to provide a mobile phone number.`
  } else if (!hasProperlyFormattedMobilePhone) {
    invalidMessage = `You need to provide a valid phone number.`
  } else if (!isRequestedEventDateAfterToday) {
    invalidMessage = `We do not take same day orders.`
  }

  return [invalidMessage === null, invalidMessage]
}